const Rails = require("@rails/ujs");
Rails.start();

const Turbolinks = require('turbolinks');
Turbolinks.start();

require('../assets/sessions.scss');

document.addEventListener('turbolinks:load', () => {
  // ***
  // Hide flash messages after a timout of 3 seconds
  // ***
  const flashMessages = document.getElementById('flashMessages');
  if (flashMessages) {
    setTimeout(() => {
      flashMessages.classList.add('flash-messages-leave');
    }, 3000);
  }

  // ***
  // Login form behavior
  // ***
  const
    loginTenant = document.getElementById('loginTenant');
  const loginEmail = document.getElementById('loginEmail');
  const loginPassword = document.getElementById('loginPassword');
  const checkInputValuePresence = (event, labelElement) => {
    if (event.target.value !== '') {
      labelElement.classList.add('present');
    } else {
      labelElement.classList.remove('present');
    }
  };

  if (loginTenant) {
    loginTenant.addEventListener('input', (event) => {
      const labelElement = document.getElementById('loginTenantLabel');
      checkInputValuePresence(event, labelElement);
    }, false);
  };

  if (loginEmail && loginPassword) {
    loginEmail.addEventListener('input', (event) => {
      const labelElement = document.getElementById('loginEmailLabel');
      checkInputValuePresence(event, labelElement);
    }, false);

    loginPassword.addEventListener('input', (event) => {
      const labelElement = document.getElementById('loginPasswordLabel');
      checkInputValuePresence(event, labelElement);
    }, false);
  };
});
